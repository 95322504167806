.searchFieldBox {
  display: block;
  padding: 5px;
  border: 1px solid #e8e8f7;
  border-radius: 7px;
  margin-top: 10px;
  position: relative;
  transition: all 0.2s ease-out;
  position: relative;
  height: 38px;
  .searchedItemWrapper {
    position: absolute;
    top: 37px;

    max-height: 200px;
    overflow-y: auto;
    width: 100%;
    background-color: white;
    border: 1px solid #d6d6d6;
    right: -2px;
    z-index: 100;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    .loading {
      display: block;
      text-align: center;
      padding: 9px;
      font-size: 13px;
    }
    .item {
      padding: 10px;
      display: block;
      background-color: white;
      cursor: pointer;
      transition: all 0.2s linear;
      .searchTitle {
        display: block;

        font-size: 15px;
      }
      .code {
        display: block;
        font-size: 13px;
      }
      &:hover {
        background-color: whitesmoke;
      }
    }
  }

  .label {
    position: absolute;
    top: 6px;
    right: 10px;
    font-size: 14px;
    cursor: auto;
    transition: all 0.2s ease-out;
    color: #8f8fb1;
    &.top {
      top: -10px;
      font-size: 12px;
      right: 9px;
      background-color: white;
      padding-right: 5px;
      padding-left: 5px;
    }
    &.bottom {
      transform: scale(1);
      top: 6px;
      right: 10px;
      background: none;
      padding-right: 5px;
      padding-left: 5px;
    }
  }
  input {
    border: none !important;
    outline: none !important;
    background-color: white !important;
    display: block;
    width: 100%;
    padding-top: 2px;
    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
  }
}

