#manFront .st0 {
  fill: #d1d4eb;
  stroke: #6a78ba;
  stroke-width: 1.5854;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#manFront .st1 {
  fill: #f0f0f8;
  stroke: #6a79ba;
  stroke-width: 1.585;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#manFront .st2 {
  display: none;
  fill: #a347f1;
  stroke: #ffffff;
  stroke-width: 3.713;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#manFront .st3 {
  display: none;
  fill: #855fa8;
  stroke: #ffffff;
  stroke-width: 3.713;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#manFront .st4 {
  fill: #eff0f8;
  stroke: #6a78ba;
  stroke-width: 1.5854;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#manFront .st5 {
  fill: #ffffff;
  stroke: #6a78ba;
  stroke-width: 1.5854;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#manFront .st6 {
  fill: #d1d4eb;
  stroke: #6a78ba;
  stroke-width: 1.585;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#manFront .st7 {
  display: none;
  fill: #855ea7;
  stroke: #ffffff;
  stroke-width: 3.713;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#manFront .st8 {
  fill: #d1d4eb;
  stroke: #6a79ba;
  stroke-width: 1.585;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#manFront .st9 {
  display: none;
  fill: #845ba6;
  stroke: #ffffff;
  stroke-width: 3.713;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#manFront .st10 {
  display: none;
  fill: #855ca6;
  stroke: #ffffff;
  stroke-width: 3.713;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#manFront .st11 {
  display: none;
  fill: #eff0f8;
  stroke: #6a78ba;
  stroke-width: 1.5854;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#manFront .st12 {
  display: none;
}
#manFront .st13 {
  display: inline;
  fill: #eff0f8;
  stroke: #6a78ba;
  stroke-width: 1.5854;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#manFront .st14 {
  display: inline;
  fill: #cfcce6;
  stroke: #ffffff;
  stroke-width: 3.7132;
  stroke-miterlimit: 10;
}
#manFront .st15 {
  display: none;
  fill: #835aa6;
  stroke: #ffffff;
  stroke-width: 3.7132;
  stroke-miterlimit: 10;
}
#manFront .st16 {
  fill: #f0f0f8;
  stroke: #6a79ba;
  stroke-width: 1.585;
  stroke-miterlimit: 10;
}
#manFront .st17 {
  display: none;
  fill: #855ca6;
  stroke: #ffffff;
  stroke-width: 3.713;
  stroke-miterlimit: 10;
}
#manFront .st18 {
  fill: #f0f0f8;
  stroke: #6a78ba;
  stroke-width: 1.5854;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#manFront .st19 {
  display: none;
  fill: #845ba6;
  stroke: #ffffff;
  stroke-width: 3.713;
  stroke-miterlimit: 10;
}
#manFront .st20 {
  fill: #545554;
}
#manFront .st21 {
  fill: #f0f0f8;
  stroke: #6a79bb;
  stroke-width: 1.585;
  stroke-miterlimit: 10;
}
#manFront .st22 {
  display: inline;
}
#manFront .st23 {
  display: inline;
  fill: #d1d4eb;
  stroke: #6a78ba;
  stroke-width: 1.5854;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#manFront .st24 {
  fill: none;
  stroke: #6a78ba;
  stroke-width: 1.5854;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#manFront .st25 {
  fill: #f0a798;
  stroke: #6a78ba;
  stroke-width: 3.7132;
  stroke-miterlimit: 10;
}
#manFront path {
  transition: all 0.2s linear;
  cursor: pointer;
}
#manFront .activeMuscle {
  fill: #a347f1;
}/*# sourceMappingURL=style.css.map */