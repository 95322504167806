#manBack {
  .st0 {
    fill: #d1d4eb;
    stroke: #6a79ba;
    stroke-width: 1.5854;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st1 {
    fill: #f0f0f8;
    stroke: #6a79ba;
    stroke-width: 1.5854;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st2 {
    display: none;
    fill: #855ca6;
    stroke: #ffffff;
    stroke-width: 3.713;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st3 {
    display: none;
    fill: #855da7;
    stroke: #ffffff;
    stroke-width: 3.713;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st4 {
    fill: #d1d4eb;
    stroke: #6a78ba;
    stroke-width: 1.5854;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st5 {
    fill: #545554;
  }
  .st6 {
    fill: #f0f0f8;
    stroke: #6a79ba;
    stroke-width: 1.585;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st7 {
    display: none;
    fill: #855ea7;
    stroke: #ffffff;
    stroke-width: 3.713;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st8 {
    fill: #eff0f8;
    stroke: #6a78ba;
    stroke-width: 1.5854;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st9 {
    opacity: 0.61;
    fill: #ffffff;
    stroke: #565692;
    stroke-width: 1.585;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st10 {
    display: none;
    fill: #855fa8;
    stroke: #ffffff;
    stroke-width: 3.713;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st11 {
    display: none;
    fill: #8561a9;
    stroke: #ffffff;
    stroke-width: 3.713;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st12 {
    fill: #f0f0f8;
    stroke: #6a79ba;
    stroke-width: 1.5854;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }

  .st13 {
    display: none;
    fill: #855ea7;
    stroke: #ffffff;
    stroke-width: 3.713;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st14 {
    fill: #eff0f8;
    stroke: #6a79ba;
    stroke-width: 1.585;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st15 {
    fill: #eff0f8;
    stroke: #6a78ba;
    stroke-width: 1.585;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st16 {
    display: none;
    fill: #8561a9;
    stroke: #f1f2f2;
    stroke-width: 3.713;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st17 {
    fill: #f0f0f8;
    stroke: #6a79ba;
    stroke-width: 1.585;
    stroke-miterlimit: 10;
  }
  .st18 {
    display: none;
    fill: #8561a9;
    stroke: #ffffff;
    stroke-width: 3.713;
    stroke-miterlimit: 10;
  }
  .st19 {
    fill: #d1d4eb;
    stroke: #6a79ba;
    stroke-width: 1.585;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st20 {
    display: none;
    fill: #8560a8;
    stroke: #ffffff;
    stroke-width: 3.713;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st21 {
    display: none;
  }
  .st22 {
    display: inline;
    fill: #d1d4eb;
    stroke: #6a78ba;
    stroke-width: 1.5854;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st23 {
    display: inline;
    fill: #eff0f8;
    stroke: #6a78ba;
    stroke-width: 1.5854;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st24 {
    display: inline;
    fill: #ffffff;
    stroke: #6a78ba;
    stroke-width: 1.5854;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st25 {
    display: inline;
    fill: #d1d4eb;
    stroke: #6a78ba;
    stroke-width: 1.585;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st26 {
    display: inline;
    fill: #d1d4eb;
    stroke: #6a79ba;
    stroke-width: 1.585;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st27 {
    display: inline;
  }
  .st28 {
    display: inline;
    fill: #845ba6;
    stroke: #ffffff;
    stroke-width: 3.713;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st29 {
    display: inline;
    fill: #f0f0f8;
    stroke: #6a79ba;
    stroke-width: 1.585;
    stroke-miterlimit: 10;
  }
  .st30 {
    display: inline;
    fill: #f0f0f8;
    stroke: #6a79bb;
    stroke-width: 1.585;
    stroke-miterlimit: 10;
  }
  .st31 {
    display: inline;
    fill: #f0f0f8;
    stroke: #6a79ba;
    stroke-width: 1.585;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st32 {
    display: inline;
    fill: #f0f0f8;
    stroke: #6a78ba;
    stroke-width: 1.5854;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st33 {
    display: inline;
    fill: #545554;
  }
  .st34 {
    fill: none;
    stroke: #6a78ba;
    stroke-width: 1.5854;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st35 {
    fill: #f0a798;
    stroke: #e55752;
    stroke-width: 3.7132;
    stroke-miterlimit: 10;
  }
  path {
    transition: all 0.2s linear;
    cursor: pointer;
  }
  .activeMuscle {
    fill: #a347f1;
  }
}
