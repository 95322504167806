.activitySelector .rc-slider-rail {
  height: 16px !important;
  background-color: #f4f4f4;
}
.activitySelector .rc-slider-handle {
  margin-top: -3px !important;
  width: 23px !important;
  height: 23px !important;
  border-color: #926cff;
  border: 7px solid #926cff;
  opacity: 1 !important;
}
.activitySelector .rc-slider-handle-dragging {
  border-color: #926cff !important;
  box-shadow: none !important;
}
/* .activitySelector .rc-slider-handle:hover{
border-color:
}
 */
.activitySelector .rc-slider-step {
  height: 16px !important;
}
.activitySelector .rc-slider-track {
  height: 16px !important;
  background-color: #926cff30 !important;
}

.activitySelector .rc-slider-dot {
  height: 10px !important;
  width: 10px !important;
  bottom: 3px !important;
  background-color: #b5b5b5;
  border: none !important;
}
.activitySelector .rc-slider-mark-text {
  margin-top: 10px;
  min-width: 80px;
}
