#manBack .st0 {
  fill: #d1d4eb;
  stroke: #6a79ba;
  stroke-width: 1.5854;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#manBack .st1 {
  fill: #f0f0f8;
  stroke: #6a79ba;
  stroke-width: 1.5854;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#manBack .st2 {
  display: none;
  fill: #855ca6;
  stroke: #ffffff;
  stroke-width: 3.713;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#manBack .st3 {
  display: none;
  fill: #855da7;
  stroke: #ffffff;
  stroke-width: 3.713;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#manBack .st4 {
  fill: #d1d4eb;
  stroke: #6a78ba;
  stroke-width: 1.5854;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#manBack .st5 {
  fill: #545554;
}
#manBack .st6 {
  fill: #f0f0f8;
  stroke: #6a79ba;
  stroke-width: 1.585;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#manBack .st7 {
  display: none;
  fill: #855ea7;
  stroke: #ffffff;
  stroke-width: 3.713;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#manBack .st8 {
  fill: #eff0f8;
  stroke: #6a78ba;
  stroke-width: 1.5854;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#manBack .st9 {
  opacity: 0.61;
  fill: #ffffff;
  stroke: #565692;
  stroke-width: 1.585;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#manBack .st10 {
  display: none;
  fill: #855fa8;
  stroke: #ffffff;
  stroke-width: 3.713;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#manBack .st11 {
  display: none;
  fill: #8561a9;
  stroke: #ffffff;
  stroke-width: 3.713;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#manBack .st12 {
  fill: #f0f0f8;
  stroke: #6a79ba;
  stroke-width: 1.5854;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#manBack .st13 {
  display: none;
  fill: #855ea7;
  stroke: #ffffff;
  stroke-width: 3.713;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#manBack .st14 {
  fill: #eff0f8;
  stroke: #6a79ba;
  stroke-width: 1.585;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#manBack .st15 {
  fill: #eff0f8;
  stroke: #6a78ba;
  stroke-width: 1.585;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#manBack .st16 {
  display: none;
  fill: #8561a9;
  stroke: #f1f2f2;
  stroke-width: 3.713;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#manBack .st17 {
  fill: #f0f0f8;
  stroke: #6a79ba;
  stroke-width: 1.585;
  stroke-miterlimit: 10;
}
#manBack .st18 {
  display: none;
  fill: #8561a9;
  stroke: #ffffff;
  stroke-width: 3.713;
  stroke-miterlimit: 10;
}
#manBack .st19 {
  fill: #d1d4eb;
  stroke: #6a79ba;
  stroke-width: 1.585;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#manBack .st20 {
  display: none;
  fill: #8560a8;
  stroke: #ffffff;
  stroke-width: 3.713;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#manBack .st21 {
  display: none;
}
#manBack .st22 {
  display: inline;
  fill: #d1d4eb;
  stroke: #6a78ba;
  stroke-width: 1.5854;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#manBack .st23 {
  display: inline;
  fill: #eff0f8;
  stroke: #6a78ba;
  stroke-width: 1.5854;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#manBack .st24 {
  display: inline;
  fill: #ffffff;
  stroke: #6a78ba;
  stroke-width: 1.5854;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#manBack .st25 {
  display: inline;
  fill: #d1d4eb;
  stroke: #6a78ba;
  stroke-width: 1.585;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#manBack .st26 {
  display: inline;
  fill: #d1d4eb;
  stroke: #6a79ba;
  stroke-width: 1.585;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#manBack .st27 {
  display: inline;
}
#manBack .st28 {
  display: inline;
  fill: #845ba6;
  stroke: #ffffff;
  stroke-width: 3.713;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#manBack .st29 {
  display: inline;
  fill: #f0f0f8;
  stroke: #6a79ba;
  stroke-width: 1.585;
  stroke-miterlimit: 10;
}
#manBack .st30 {
  display: inline;
  fill: #f0f0f8;
  stroke: #6a79bb;
  stroke-width: 1.585;
  stroke-miterlimit: 10;
}
#manBack .st31 {
  display: inline;
  fill: #f0f0f8;
  stroke: #6a79ba;
  stroke-width: 1.585;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#manBack .st32 {
  display: inline;
  fill: #f0f0f8;
  stroke: #6a78ba;
  stroke-width: 1.5854;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#manBack .st33 {
  display: inline;
  fill: #545554;
}
#manBack .st34 {
  fill: none;
  stroke: #6a78ba;
  stroke-width: 1.5854;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#manBack .st35 {
  fill: #f0a798;
  stroke: #e55752;
  stroke-width: 3.7132;
  stroke-miterlimit: 10;
}
#manBack path {
  transition: all 0.2s linear;
  cursor: pointer;
}
#manBack .activeMuscle {
  fill: #a347f1;
}/*# sourceMappingURL=style.css.map */