body {
  background-color: white !important;
}
.bg-heroGr {
  background: rgba(240, 36, 131, 1);
  background: -moz-linear-gradient(
    -45deg,
    rgba(240, 36, 131, 1) 0%,
    rgba(84, 8, 170, 1) 53%,
    rgba(84, 8, 170, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    right bottom,
    color-stop(0%, rgba(240, 36, 131, 1)),
    color-stop(53%, rgba(84, 8, 170, 1)),
    color-stop(100%, rgba(84, 8, 170, 1))
  );
  background: -webkit-linear-gradient(
    -45deg,
    rgba(240, 36, 131, 1) 0%,
    rgba(84, 8, 170, 1) 53%,
    rgba(84, 8, 170, 1) 100%
  );
  background: -o-linear-gradient(
    -45deg,
    rgba(240, 36, 131, 1) 0%,
    rgba(84, 8, 170, 1) 53%,
    rgba(84, 8, 170, 1) 100%
  );
  background: -ms-linear-gradient(
    -45deg,
    rgba(240, 36, 131, 1) 0%,
    rgba(84, 8, 170, 1) 53%,
    rgba(84, 8, 170, 1) 100%
  );
  background: linear-gradient(
    135deg,
    rgba(240, 36, 131, 1) 0%,
    rgba(84, 8, 170, 1) 53%,
    rgba(84, 8, 170, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f02483', endColorstr='#5408aa', GradientType=1 );
}
.glass {
  background: rgba(255, 255, 255, 0.22);

  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10.9px);
  -webkit-backdrop-filter: blur(10.9px);
  border: 1px solid rgba(255, 255, 255, 0.23);
}

.homeSection2 {
  height: 540px;
  margin-top: 40px;
  background: linear-gradient(131.03deg, white 0%, rgba(18, 131, 169, 0.087))-7.63%
      padding-box,
    linear-gradient(
        to right,
        rgba(251, 211, 230, 0),
        rgba(202, 242, 255, 0.596)
      )
      border-box;
  border-radius: 50px;
  border: 4px solid transparent;
}
.homeSection3 {
  height: 507px;
  /*  margin-top: 100px; */
  background: linear-gradient(131.03deg, white 0%, rgba(211, 236, 251, 0.247))-7.63%
      padding-box,
    linear-gradient(to right, rgba(251, 211, 230, 0), #b7e9f76e) border-box;
  border-radius: 50px;
  border: 4px solid transparent;
}
.homeSection5 {
  height: 500px;
  margin-top: 100px;
  border-radius: 50px;
  border: 4px solid transparent;
  background: linear-gradient(131.03deg, white 0%, rgb(241 232 252 / 20%)) -7.63%
      padding-box,
    linear-gradient(to left, rgba(251, 211, 230, 0), rgb(241 232 252))
      border-box;
}
.tinyMenu {
  @apply bg-purple transition-all duration-500;
}
.container {
  width: 1360px;
  margin: auto;
}
