@tailwind base;
@tailwind components;
@tailwind utilities;
/*
Yekan Bakh fonts are considered a proprietary software. To gain information about the laws
regarding the use of these fonts, please visit www.fontiran.com
---------------------------------------------------------------------
This set of fonts are used in this project under the license: (F5ISP1AT)
---------------------------------------------------------------------
*/
@font-face {
  font-family: YekanBakh;
  font-style: normal;
  font-weight: 100;
  font-stretch: normal;
  src: url("./assets/fonts/yekanBakh/woff/YekanBakhFaNum-Thin.woff") format("woff"), url("./assets/fonts/yekanBakh/woff2/YekanBakhFaNum-Thin.woff2") format("woff2");
}

@font-face {
  font-family: YekanBakh;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
  src: url("./assets/fonts/yekanBakh/woff/YekanBakhFaNum-Light.woff") format("woff"), url("./assets/fonts/yekanBakh/woff2/YekanBakhFaNum-Light.woff2") format("woff2");
}

@font-face {
  font-family: YekanBakh;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  src: url("./assets/fonts/yekanBakh/woff/YekanBakhFaNum-Regular.woff") format("woff"), url("./assets/fonts/yekanBakh/woff2/YekanBakhFaNum-Regular.woff2") format("woff2");
}

@font-face {
  font-family: YekanBakh;
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
  src: url("./assets/fonts/yekanBakh/woff/YekanBakhFaNum-SemiBold.woff") format("woff"), url("./assets/fonts/yekanBakh/woff2/YekanBakhFaNum-SemiBold.woff2") format("woff2");
}

@font-face {
  font-family: YekanBakh;
  font-style: normal;
  font-weight: bold;
  font-stretch: normal;
  src: url("./assets/fonts/yekanBakh/woff/YekanBakhFaNum-Bold.woff") format("woff"), url("./assets/fonts/yekanBakh/woff2/YekanBakhFaNum-Bold.woff2") format("woff2");
}

@font-face {
  font-family: YekanBakh;
  font-style: normal;
  font-weight: 800;
  font-stretch: normal;
  src: url("./assets/fonts/yekanBakh/woff/YekanBakhFaNum-ExtraBold.woff") format("woff"), url("./assets/fonts/yekanBakh/woff2/YekanBakhFaNum-ExtraBold.woff2") format("woff2");
}

@font-face {
  font-family: YekanBakh;
  font-style: normal;
  font-weight: 900;
  font-stretch: normal;
  src: url("./assets/fonts/yekanBakh/woff/YekanBakhFaNum-Black.woff") format("woff"), url("./assets/fonts/yekanBakh/woff2/YekanBakhFaNum-Black.woff2") format("woff2");
}

@font-face {
  font-family: YekanBakh;
  font-style: normal;
  font-weight: 950;
  font-stretch: normal;
  src: url("./assets/fonts/yekanBakh/woff/YekanBakhFaNum-ExtraBlack.woff") format("woff"), url("./assets/fonts/yekanBakh/woff2/YekanBakhFaNum-ExtraBlack.woff2") format("woff2");
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  padding: 0;
}
.ltr {
  direction: ltr;
}
@media screen and (min-width: 768px) {
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  ::-webkit-scrollbar {
    width: 4px;
    height: 5px;
    border-radius: 40px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 40px;
  }
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 40px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    border-radius: 40px;
  }
}

.bodyRtl {
  font-family: YekanBakh, sans-serif;
  scroll-behavior: smooth;
}
.bodyRtl .langBox {
  font-family: YekanBakh;
  position: absolute;
  right: 240px;
}
@media screen and (max-width: 768px) {
  .bodyRtl .langBox {
    position: static;
    display: flex;
    flex-direction: column;
  }
}
.bodyRtl .langBox button {
  margin-right: 10px;
  font-weight: 600;
}
@media screen and (max-width: 768px) {
  .bodyRtl .langBox button {
    margin-right: 0;
  }
}
.bodyRtl .langBox button.active {
  background-color: rgba(12, 12, 12, 0.2117647059);
  padding: 5px;
  border-radius: 5px;
  padding-top: 8px;
}
.bodyRtl .otherLangBox {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}

.bodyRtl .mobileMenu {
  position: fixed;
  top: 0;
  left: 0;
  justify-content: space-between;
  z-index: 100;
}
.bodyRtl .mobileMenu .logo {
  margin-left: -29px;
  width: 165px;
}

.bodyRtl .basicInfoWrapper {
  position: relative;
  height: 100vh;
}
.bodyRtl .basicInfoWrapper .title {
  font-weight: 700;
  font-size: 35px;
  font-family: YekanBakh;
}
@media screen and (max-width: 1366px) {
  .bodyRtl .basicInfoWrapper .title {
    font-size: 33px;
  }
}
.bodyRtl .basicInfoWrapper .help {
  border: 1px solid #efefef;
  border-radius: 16px;
  min-height: 100px;
  font-family: YekanBakh;
  color: rgba(0, 0, 0, 0.4);
  width: 565px;
  max-width: 100%;
  margin: auto;
}
.bodyRtl .basicInfoWrapper .help img {
  width: 30px !important;
  height: 30px !important;
  margin-left: auto;
}
.bodyRtl .basicInfoWrapper .help p {
  width: 85%;
}
.bodyRtl .basicInfoWrapper .progressBox {
  display: flex;
  width: 1360px;
  height: 41px;
  max-width: 100%;
  justify-content: center;
  background-color: rgb(252, 250, 254);
  border-radius: 24px;
  align-items: center;
  margin-top: 20px;
  margin: auto;
  position: relative;
}
@media screen and (max-width: 1366px) {
  .bodyRtl .basicInfoWrapper .progressBox {
    transform: scale(0.8);
  }
}
.bodyRtl .basicInfoWrapper .progressBox .arrow {
  width: 40px;
  height: 40px;
  position: absolute;
  cursor: pointer;
  left: 30px;
}
.bodyRtl .basicInfoWrapper .progressBox .close {
  position: absolute;
  top: 5px;
  right: 30px;
  cursor: pointer;
  width: 32px;
}
.bodyRtl .basicInfoWrapper .genderBox {
  width: 574px;
  max-width: 100%;
  margin: auto;
  margin-top: 151px;
}
@media screen and (max-width: 1366px) {
  .bodyRtl .basicInfoWrapper .genderBox {
    margin-top: 90px;
    transform: scale(0.76);
  }
}
.bodyRtl .basicInfoWrapper .genderBox .item {
  width: 46%;
  height: 269px;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
  cursor: pointer;
}
.bodyRtl .basicInfoWrapper .genderBox .item .title {
  font-family: YekanBakh;
  font-size: 16px;
  margin-top: 20px;
}
.bodyRtl .basicInfoWrapper .genderBox .item .genderImage {
  margin-top: -120px;
}
.bodyRtl .basicInfoWrapper .letStartBtn {
  border-radius: 16px;
  font-size: 20px;
  font-family: YekanBakh;
  background-color: #5408aa;
  display: block;
  padding: 10px;
  width: 430px;
  max-width: 100%;
  margin: auto;
  color: white;
  margin-top: 60px;
  transition: all 0.5s linear;
}
.bodyRtl .basicInfoWrapper .letStartBtn:disabled {
  background-color: rgb(164, 164, 164);
}
@media screen and (min-height: 741px) {
  .bodyRtl .basicInfoWrapper .letStartBtn {
    margin-top: 80px;
  }
}
.bodyRtl .basicInfoWrapper .bodyImage {
  display: block;
  margin: auto;
  height: 400px;
}
@media screen and (max-width: 1710px) {
  .bodyRtl .basicInfoWrapper .bodyImage {
    height: 250px;
  }
}
.bodyRtl .basicInfoWrapper .bodyFatSelectorBox {
  border-radius: 6px;
  background-color: rgb(244, 244, 244);
  width: 386px;
  max-width: 100%;
  margin: auto;
  position: relative;
  border-radius: 6px;
  background-color: rgb(244, 244, 244);
  width: 386px;
  max-width: 100%;
  margin: auto;
  position: relative;
  height: 17px;
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}
@media screen and (max-width: 1366px) {
  .bodyRtl .basicInfoWrapper .bodyFatSelectorBox {
    margin-top: 20px;
  }
}
.bodyRtl .basicInfoWrapper .bodyFatSelectorBox .labelMin {
  position: absolute;
  right: 0;
  font-size: 14px;
  font-family: YekanBakh;
  top: 27px;
}
.bodyRtl .basicInfoWrapper .bodyFatSelectorBox .labelMax {
  position: absolute;
  left: 0;
  font-size: 14px;
  font-family: YekanBakh;
  top: 27px;
}
.bodyRtl .basicInfoWrapper .bodyFatSelectorBox .activeLine1 {
  position: absolute;
  height: 17px;
  width: 6%;
  background-color: rgb(0, 213, 183);
  position: absolute;
  left: 2px;
  border-radius: 6px;
  transition: all 0.2s linear;
}
.bodyRtl .basicInfoWrapper .bodyFatSelectorBox .activeLine2 {
  position: absolute;
  height: 17px;
  width: 29%;
  background-color: #ff9a7c;
  position: absolute;
  left: 2px;
  border-radius: 6px;
  transition: all 0.2s linear;
}
.bodyRtl .basicInfoWrapper .bodyFatSelectorBox .activeLine3 {
  position: absolute;
  height: 17px;
  width: 50%;
  background-color: #feb137;
  position: absolute;
  left: 2px;
  border-radius: 6px;
  transition: all 0.2s linear;
}
.bodyRtl .basicInfoWrapper .bodyFatSelectorBox .activeLine4 {
  position: absolute;
  height: 17px;
  width: 73%;
  background-color: #6c95ff;
  position: absolute;
  left: 2px;
  border-radius: 6px;
  transition: all 0.2s linear;
}
.bodyRtl .basicInfoWrapper .bodyFatSelectorBox .activeLine5 {
  position: absolute;
  height: 17px;
  width: 100%;
  background-color: #926cff;
  position: absolute;
  left: 2px;
  border-radius: 6px;
  transition: all 0.2s linear;
}
.bodyRtl .basicInfoWrapper .bodyFatSelectorBox * {
  transition: all 0.1s linear;
}
.bodyRtl .basicInfoWrapper .bodyFatSelectorBox .item {
  width: 10px;
  height: 10px;
  background-color: rgb(181, 181, 181);
  display: block;
  border-radius: 50px;
  cursor: pointer;
  position: relative;
}
.bodyRtl .basicInfoWrapper .bodyFatSelectorBox .item .tooltip {
  font-family: YekanBakh;
  font-size: 16px;
  position: absolute;
  top: -25px;
  display: none;
  width: 70px;
  margin-right: 23px;
}
.bodyRtl .basicInfoWrapper .bodyFatSelectorBox .item.active {
  background-color: white;
  border: 3px solid rgb(146, 108, 255);
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bodyRtl .basicInfoWrapper .bodyFatSelectorBox .item.active .tooltip {
  display: block;
}
.bodyRtl .basicInfoWrapper .bodyFatSelectorBox .item.active .core {
  display: block;
  background-color: rgb(146, 108, 255);
  width: 11px;
  height: 11px;
  border-radius: 30px;
}
.bodyRtl .basicInfoWrapper .goalBox {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  width: 670px;
  max-width: 100%;
  cursor: pointer;
  min-height: 100px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.bodyRtl .basicInfoWrapper .goalBox.active {
  border: 1px solid rgb(86, 6, 168);
}
.bodyRtl .basicInfoWrapper .goalBox img {
  width: 140px;
}
.bodyRtl .basicInfoWrapper .goalBox .text {
  color: rgba(0, 0, 0, 0.7);
  font-family: YekanBakh;
  font-size: 18px;
}
.bodyRtl .basicInfoWrapper .swiperWrapper {
  width: 550px;
  max-width: 100%;
  margin: auto;
}
.bodyRtl .basicInfoWrapper .swiperWrapper .swiper-slide {
  height: 180px;
  display: flex;
  justify-content: center;
  margin-top: 30px;
  cursor: pointer;
}
.bodyRtl .basicInfoWrapper .swiperWrapper .swiper-slide .myRing {
  border: 14px solid rgba(163, 71, 241, 0.09);
  width: 170px;
  height: 170px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bodyRtl .basicInfoWrapper .swiperWrapper .swiperInfo .title {
  font-family: YekanBakh;
  font-size: 20px;
  text-align: center;
  display: block;
}
.bodyRtl .basicInfoWrapper .swiperWrapper .swiperInfo .text {
  font-family: YekanBakh;
  font-size: 18px;
  text-align: center;
  display: block;
  min-height: 54px;
}
.bodyRtl .basicInfoWrapper .swiperWrapper .swiperStatus {
  display: flex;
  justify-content: space-around;
  align-items: baseline;
}
.bodyRtl .basicInfoWrapper .swiperWrapper .swiperStatus.active1 .bar:nth-child(1) {
  background-color: rgb(0, 213, 183);
}
.bodyRtl .basicInfoWrapper .swiperWrapper .swiperStatus.active2 .bar:nth-child(1) {
  background-color: rgb(253, 131, 188);
}
.bodyRtl .basicInfoWrapper .swiperWrapper .swiperStatus.active2 .bar:nth-child(2) {
  background-color: rgb(253, 131, 188);
}
.bodyRtl .basicInfoWrapper .swiperWrapper .swiperStatus.active3 .bar:nth-child(1) {
  background-color: rgb(255, 154, 124);
}
.bodyRtl .basicInfoWrapper .swiperWrapper .swiperStatus.active3 .bar:nth-child(2) {
  background-color: rgb(255, 154, 124);
}
.bodyRtl .basicInfoWrapper .swiperWrapper .swiperStatus.active3 .bar:nth-child(3) {
  background-color: rgb(255, 154, 124);
}
.bodyRtl .basicInfoWrapper .swiperWrapper .swiperStatus.active4 .bar:nth-child(1) {
  background-color: rgb(108, 149, 255);
}
.bodyRtl .basicInfoWrapper .swiperWrapper .swiperStatus.active4 .bar:nth-child(2) {
  background-color: rgb(108, 149, 255);
}
.bodyRtl .basicInfoWrapper .swiperWrapper .swiperStatus.active4 .bar:nth-child(3) {
  background-color: rgb(108, 149, 255);
}
.bodyRtl .basicInfoWrapper .swiperWrapper .swiperStatus.active4 .bar:nth-child(4) {
  background-color: rgb(108, 149, 255);
}
.bodyRtl .basicInfoWrapper .swiperWrapper .swiperStatus.active5 .bar {
  background-color: rgb(146, 108, 255);
}
.bodyRtl .basicInfoWrapper .swiperWrapper .swiperStatus .bar {
  display: block;
  width: 38px;
  border-radius: 33px;
  background-color: rgb(244, 244, 244);
}
.bodyRtl .basicInfoWrapper .swiperWrapper .swiperStatus .bar1 {
  height: 60px;
}
.bodyRtl .basicInfoWrapper .swiperWrapper .swiperStatus .bar2 {
  height: 84px;
}
.bodyRtl .basicInfoWrapper .swiperWrapper .swiperStatus .bar3 {
  height: 118px;
}
.bodyRtl .basicInfoWrapper .swiperWrapper .swiperStatus .bar4 {
  height: 147px;
}
.bodyRtl .basicInfoWrapper .swiperWrapper .swiperStatus .bar5 {
  height: 173px;
}

.bodyRtl .basicInfoWrapper .rangeWrapper {
  height: 500px;
  text-align: center;
  height: 320px;
  width: 700px;
  text-align: right;
  margin: auto;
  margin-top: 30px;
  position: relative;
  direction: ltr;
  overflow: hidden;
}
@media screen and (max-width: 1536px) {
  .bodyRtl .basicInfoWrapper .rangeWrapper {
    transform: scale(0.8);
    margin-top: -21px;
  }
}
.bodyRtl .basicInfoWrapper .rangeWrapper .wightInfo {
  display: block;
  margin: auto;
  font-size: 40px;
  text-align: center;
  font-family: YekanBakh;
  direction: rtl;
}
.bodyRtl .basicInfoWrapper .rangeWrapper .ruler {
  height: 304px;
  width: 38px;
  position: absolute;
  right: 132px;
  top: 7px;
  /* z-index: 1; */
  direction: rtl;
}
.bodyRtl .basicInfoWrapper .rangeWrapper .ruler .item {
  display: block;
  width: 22px;
  height: 4px;
  background-color: silver;
  margin-top: 6px;
}
.bodyRtl .basicInfoWrapper .rangeWrapper .ruler .item-big {
  display: block;
  width: 38px;
  height: 4px;
  background-color: silver;
  margin-top: 6px;
}
.bodyRtl .basicInfoWrapper .rangeWrapper .weightRuler {
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  right: 10px;
  top: 7px;
  /* z-index: 1; */
  direction: rtl;
}
.bodyRtl .basicInfoWrapper .rangeWrapper .weightRuler .item {
  width: 4px;
  height: 22px;
  background-color: silver;
}
.bodyRtl .basicInfoWrapper .rangeWrapper .weightRuler .item-big {
  width: 4px;
  height: 38px;
  background-color: silver;
}
.bodyRtl .basicInfoWrapper .rangeWrapper .human {
  position: absolute;
  /*   top: 0; */
  height: 325px;
  right: 28%;
  z-index: 0;
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.bodyRtl .basicInfoWrapper .valueChangerBox {
  background-color: rgb(244, 244, 244);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  height: 56px;
  width: 300px;
  border-radius: 12px;
  margin: auto;
  margin-top: 10px;
}
@media screen and (max-width: 1366px) {
  .bodyRtl .basicInfoWrapper .valueChangerBox {
    margin-top: 10px;
  }
}
.bodyRtl .basicInfoWrapper .valueChangerBox .operator {
  cursor: pointer;
  background: none;
  outline: none;
  width: 30px;
  height: 30px;
  text-align: center;
}
.bodyRtl .basicInfoWrapper .valueChangerBox .operator img {
  width: 23px;
  margin: auto;
}
.bodyRtl .basicInfoWrapper .valueChangerBox .text .value {
  font-size: 24px;
  font-family: YekanBakh;
}
.bodyRtl .basicInfoWrapper .valueChangerBox .text .unit {
  font-size: 20px;
  font-family: YekanBakh;
  margin-left: 5px;
}
.bodyRtl .basicInfoWrapper .unitBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  height: 56px;
  width: 300px;
  margin: auto;
}
.bodyRtl .basicInfoWrapper .unitBox .item {
  border-radius: 18px;
  background-color: rgb(244, 244, 244);
  color: rgba(0, 0, 0, 0.4);
  width: 128px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  font-size: 18px;
  font-family: YekanBakh;
}
.bodyRtl .basicInfoWrapper .unitBox .item.active {
  background-color: #5408aa;
  color: white;
}
.bodyRtl .roadMapWrapper {
  /* padding-top: 96px;
  padding-bottom: 120px; */
  width: 1360px;
  max-width: 100%;
  margin: auto;
  padding-top: 80px;
}

.SportTimeSelector {
  border-radius: 6px;
  background-color: rgb(244, 244, 244);
  max-width: 100%;
  position: relative;
  border-radius: 6px;
  background-color: rgb(244, 244, 244);
  width: 336px;
  max-width: 100%;
  position: relative;
  height: 17px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}
@media screen and (max-width: 1366px) {
  .SportTimeSelector {
    margin-top: 20px;
  }
}
.SportTimeSelector .labelMin {
  position: absolute;
  right: 0;
  font-size: 14px;
  font-family: YekanBakh;
  top: 27px;
}
.SportTimeSelector .labelMax {
  position: absolute;
  left: 0;
  font-size: 14px;
  font-family: YekanBakh;
  top: 27px;
}
.SportTimeSelector .activeLine1 {
  position: absolute;
  height: 17px;
  width: 6%;
  background-color: rgb(0, 213, 183);
  position: absolute;
  left: 2px;
  border-radius: 6px;
  transition: all 0.2s linear;
}
.SportTimeSelector .activeLine2 {
  position: absolute;
  height: 17px;
  width: 29%;
  background-color: #ff9a7c;
  position: absolute;
  left: 2px;
  border-radius: 6px;
  transition: all 0.2s linear;
}
.SportTimeSelector .activeLine3 {
  position: absolute;
  height: 17px;
  width: 50%;
  background-color: #feb137;
  position: absolute;
  left: 2px;
  border-radius: 6px;
  transition: all 0.2s linear;
}
.SportTimeSelector .activeLine4 {
  position: absolute;
  height: 17px;
  width: 73%;
  background-color: #6c95ff;
  position: absolute;
  left: 2px;
  border-radius: 6px;
  transition: all 0.2s linear;
}
.SportTimeSelector .activeLine5 {
  position: absolute;
  height: 17px;
  width: 100%;
  background-color: #926cff;
  position: absolute;
  left: 2px;
  border-radius: 6px;
  transition: all 0.2s linear;
}
.SportTimeSelector * {
  transition: all 0.1s linear;
}
.SportTimeSelector .item {
  width: 10px;
  height: 10px;
  background-color: rgb(181, 181, 181);
  display: block;
  border-radius: 50px;
  cursor: pointer;
  position: relative;
}
.SportTimeSelector .item .tooltip {
  font-family: YekanBakh;
  font-size: 12px;
  position: absolute;
  top: 30px;
  display: block;
  width: 46px;
  height: 40px;
}
.SportTimeSelector .item.active {
  background-color: white;
  border: 3px solid rgb(146, 108, 255);
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* .tooltip {
    display: block;
  } */
}
.SportTimeSelector .item.active .core {
  display: block;
  background-color: rgb(146, 108, 255);
  width: 11px;
  height: 11px;
  border-radius: 30px;
}

.body-map__muscle {
  color: silver;
}
.body-map__muscle:hover {
  color: red;
}

.bg-heroGr {
  background: #f02483;
  background: -webkit-gradient(left top, right bottom, color-stop(0%, rgb(240, 36, 131)), color-stop(53%, #5408aa), color-stop(100%, rgb(84, 8, 170)));
  background: linear-gradient(135deg, rgb(240, 36, 131) 0%, rgb(84, 8, 170) 53%, rgb(84, 8, 170) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#f02483", endColorstr="#5408aa", GradientType=1 );
}

.glass {
  background: rgba(255, 255, 255, 0.22);
  /* border-radius: 16px; */
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10.9px);
  -webkit-backdrop-filter: blur(10.9px);
  border: 1px solid rgba(255, 255, 255, 0.23);
}

.container {
  width: 1360px;
  margin: auto;
}

label {
  color: black;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #5606a8 !important;
  border-color: #5606a8 !important;
}
.ant-checkbox-group {
  margin: 0 !important;
}
.diet .ant-collapse-content-box {
  padding: 0 !important;
}
.frontArrow {
  width: 80px;
  height: 34px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #7639ba;
  text-align: center;
  font-family: YekanBakh;
  cursor: pointer;
}
.frontArrow:after {
  content: "";
  position: absolute;
  left: -10px;
  bottom: 0;
  width: 0;
  height: 0;
  border-right: 10px solid #7639ba;
  border-top: 17px solid transparent;
  border-bottom: 17px solid transparent;
}

.frontArrow.pink {
  width: 80px;
  height: 34px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f3509d;
  text-align: center;
  font-family: YekanBakh;
  cursor: pointer;
}
.frontArrow.pink:after {
  content: "";
  position: absolute;
  left: -10px;
  bottom: 0;
  width: 0;
  height: 0;
  border-right: 10px solid#F3509D;
  border-top: 17px solid transparent;
  border-bottom: 17px solid transparent;
}
.ltr {
  direction: ltr;
}
.rtl {
  direction: rtl;
}
.ant-pagination-item a {
  font-family: "YekanBakh" !important;
}
.scContainer {
  width: 320px;
  height: 110px;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  margin-bottom: 10px;
  border: 2px solid #333;
  overflow: scroll;
  position: relative;
  scroll-snap-type: y mandatory;
}
.scContainer .element {
  width: 300px;
  height: 90px;
  scroll-margin: 10px;
  scroll-snap-align: start;
  scroll-snap-stop: normal;
  color: white;
  font-size: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.scContainer .element:nth-child(1) {
  background: #1d1e21;
}
.scContainer .element:nth-child(2) {
  background: #ee6620;
}
.scContainer .element:nth-child(3) {
  background: #777ba0;
}
.scContainer .element:nth-child(4) {
  background: #da1e62;
}
.scContainer .element:nth-child(5) {
  background: #1d1e21;
}
.scContainer .element:nth-child(6) {
  background: #ee6620;
}
.scContainer .element:nth-child(7) {
  background: #777ba0;
}
.scContainer .element:nth-child(8) {
  background: #da1e62;
}
.scContainer .element:nth-child(9) {
  background: #1d1e21;
}
.scContainer .element:nth-child(10) {
  background: #ee6620;
}
.scContainer .element:nth-child(11) {
  background: #777ba0;
}
.scContainer .element:nth-child(12) {
  background: #da1e62;
}

@media (min-width: 765px) {
  .leaflet-container {
    width: 100%;
    height: 500px;
    border-radius: 8px;
  }
}
@media (max-width: 765px) {
  .leaflet-container {
    width: 100%;
    height: 208px;
    border-radius: 10px;
  }
}
.gradient-plan {
  background: linear-gradient(180deg, #f9f5fe 50.15%, #ffffff 95.68%);
}
.radialGradient {
  background: radial-gradient(89.57% 227.1% at 68.31% 127.1%, #00eed2 0%, #3d54a2 47.1%, #610ba6 100%);
}
.bg-radial-doctor {
  background: radial-gradient(48.45% 44.72% at 51.25% 51.49%, #ffffff 0%, #fafafa 1%, #b9b9b9 18%, #818181 34%, #535353 49%, #2f2f2f 64%, #151515 78%, #050505 90%, #000000 100%);
  background-blend-mode: color-dodge;
}

.slider-card {
  position: absolute;
  width: 185px;
  top: 43px;
  left: 0;
  animation: card-animate;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-delay: 2s;
  animation-fill-mode: forwards;
  transition: all 1s linear;
  opacity: 0;
}
.home-slider-box {
  animation: home-slider-box-animate;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  margin: auto;
}
@keyframes card-animate {
  0% {
    opacity: 1;
  }
  30% {
    opacity: 1;
  }
  100% {
    left: 135px;
    transform: scale(1.2);
    opacity: 1;
  }
}

@keyframes home-slider-box-animate {
  from {
    margin-right: 0;
  }
  to {
    margin-right: 50%;
  }
}
.react-modal-sheet-header {
  display: none !important;
}
.persian-datepicker-caption {
  padding: 10px;
}
.rmc-multi-picker {
  margin-top: 10px;
}
.persian-datepicker-sheet-footer {
  padding: 5px !important;
  height: auto !important;
}
.service-box {
  transition: all 0.3s ease;
  top: 70px;
  opacity: 1;
  visibility: visible;
}
nav.origin {
  position: fixed;
  width: 100%;
  top: 0px;
}
nav .wrapper {
}
.wrapper .logo a {
  color: #f2f2f2;
  font-size: 30px;
  font-weight: 600;
  text-decoration: none;
}
.wrapper .nav-links {
  display: flex;
  height: 100%;
}
.nav-links li {
  list-style: none;
}
.nav-links li a {
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  padding: 9px 15px;
  border-radius: 5px;
  transition: all 0.3s ease;
}
.nav-links .content li a {
  color: #000000;
}
.nav-links .content li a:hover {
  color: #5704ac;
}

.nav-links .mobile-item {
  display: none;
}
.nav-links .drop-menu {
  position: absolute;
  background: #242526;
  width: 780px;
  line-height: 45px;
  top: 85px;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}
.nav-links li:hover .drop-menu,
.nav-links li:hover .mega-box {
  transition: all 0.3s ease;
  top: 80px;
  opacity: 1;
  visibility: visible;
}
.drop-menu li a {
  width: 100%;
  display: block;
  padding: 0 0 0 15px;
  font-weight: 400;
  border-radius: 0px;
}
.mega-box {
  position: absolute;

  left: 0;
  width: 100%;
  padding: 0 30px;
  top: 95px;
  opacity: 0;
  visibility: hidden;
}
.mega-box .content {
  background: white;

  padding: 25px 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  justify-content: space-between;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}
.mega-box .content .row {
  width: calc(25% - 30px);
  line-height: 45px;
}
.content .row img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.content .row header {
  color: #f2f2f2;
  font-size: 20px;
  font-weight: 500;
}
.content .row .mega-links {
  margin-left: -40px;
  border-left: 1px solid rgba(255, 255, 255, 0.09);
}
.row .mega-links li {
  padding: 0 20px;
}
.row .mega-links li a {
  padding: 0px;
  padding: 0 20px;
  color: #d9d9d9;
  font-size: 17px;
  display: block;
}
.row .mega-links li a:hover {
  color: #f2f2f2;
}
.wrapper .btn {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  display: none;
}
.wrapper .btn.close-btn {
  position: absolute;
  right: 30px;
  top: 10px;
}

@media screen and (max-width: 970px) {
  .wrapper .btn {
    display: block;
  }
  .wrapper .nav-links {
    position: fixed;
    height: 100%;
    width: 100%;
    max-width: 350px;
    top: 0;
    left: -100%;
    background: whitesmoke;
    display: block;
    padding: 50px 10px;
    line-height: 50px;
    overflow-y: auto;
    box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.18);
    transition: all 0.3s ease;
  }
  /* custom scroll bar */
  ::-webkit-scrollbar {
    /* width: 10px; */
  }
  ::-webkit-scrollbar-track {
    background: whitesmoke;
  }
  ::-webkit-scrollbar-thumb {
    background: whitesmoke;
  }
  #menu-btn:checked ~ .nav-links {
    left: 0%;
  }
  #menu-btn:checked ~ .btn.menu-btn {
    display: none;
  }
  #close-btn:checked ~ .btn.menu-btn {
    display: block;
  }
  .nav-links li {
    margin: 15px 10px;
  }
  .nav-links li a {
    padding: 0 20px;
    display: block;
    font-size: 20px;
  }
  .nav-links .drop-menu {
    position: static;
    opacity: 1;
    top: 65px;
    visibility: visible;
    padding-left: 20px;
    width: 100%;
    max-height: 0px;
    overflow: hidden;
    box-shadow: none;
    transition: all 0.3s ease;
  }
  #showDrop:checked ~ .drop-menu,
  #showMega:checked ~ .mega-box {
    max-height: 100%;
    height: 100%;
  }
  .nav-links .desktop-item {
    display: none;
  }
  .nav-links .mobile-item {
    display: block;
    color: #f2f2f2;
    font-size: 20px;
    font-weight: 500;
    padding-left: 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: all 0.3s ease;
  }
  .nav-links .mobile-item:hover {
    background: whitesmoke;
  }
  .drop-menu li {
    margin: 0;
  }
  .drop-menu li a {
    border-radius: 5px;
    font-size: 18px;
  }
  .mega-box {
    position: static;
    top: 65px;
    opacity: 1;
    visibility: visible;
    padding: 0 20px;
    max-height: 0px;
    overflow: hidden;
    transition: all 0.3s ease;
  }
  .mega-box .content {
    box-shadow: none;
    flex-direction: column;
    padding: 20px 20px 0 20px;
  }
  .mega-box .content .row {
    width: 100%;
    margin-bottom: 15px;
    border-top: 1px solid rgba(255, 255, 255, 0.08);
  }
  .mega-box .content .row:nth-child(1),
  .mega-box .content .row:nth-child(2) {
    border-top: 0px;
  }
  .content .row .mega-links {
    border-left: 0px;
    padding-left: 15px;
  }
  .row .mega-links li {
    margin: 0;
  }
  .content .row header {
    font-size: 19px;
  }
}
nav input {
  display: none;
}

.body-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  padding: 0 30px;
}
.body-text div {
  font-size: 45px;
  font-weight: 600;
}
.toolsBg {
  background: linear-gradient(140.77deg, #f02484 -8.54%, #5408a9 58.71%, #4cc9ec 131.72%);
}

.dashboardMenuBg {
  background: rgba(240, 36, 131, 1);
  background: -moz-linear-gradient(-45deg, rgba(240, 36, 131, 1) 0%, rgba(86, 6, 168, 1) 58%, rgba(86, 6, 168, 1) 100%);
  background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(240, 36, 131, 1)), color-stop(58%, rgba(86, 6, 168, 1)), color-stop(100%, rgba(86, 6, 168, 1)));
  background: -webkit-linear-gradient(-45deg, rgba(240, 36, 131, 1) 0%, rgba(86, 6, 168, 1) 58%, rgba(86, 6, 168, 1) 100%);
  background: -o-linear-gradient(-45deg, rgba(240, 36, 131, 1) 0%, rgba(86, 6, 168, 1) 58%, rgba(86, 6, 168, 1) 100%);
  background: -ms-linear-gradient(-45deg, rgba(240, 36, 131, 1) 0%, rgba(86, 6, 168, 1) 58%, rgba(86, 6, 168, 1) 100%);
  background: linear-gradient(135deg, rgba(240, 36, 131, 1) 0%, rgba(86, 6, 168, 1) 58%, rgba(86, 6, 168, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f02483', endColorstr='#5606a8', GradientType=1 );

  -webkit-box-shadow: 0px 0px 26px -6px rgba(86, 6, 168, 1);
  -moz-box-shadow: 0px 0px 26px -6px rgba(86, 6, 168, 1);
  box-shadow: 0px 0px 26px -6px rgba(86, 6, 168, 1);
}
.tableLoader {
  display: block;
  position: relative;
  height: 4px;
  width: 100%;
  border: 1px solid #fff;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 10px;
}
.tableLoader:after {
  content: "";
  width: 40%;
  height: 100%;
  background: #141b48;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  animation: animloader 1.5s ease infinite;
}
@keyframes animloader {
  0% {
    left: 0;
    transform: translateX(-100%);
  }
  100% {
    left: 100%;
    transform: translateX(0%);
  }
}
