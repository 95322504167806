$purple:#a347f1;
#manFront{
  .st0 {
    fill: #d1d4eb;
    stroke: #6a78ba;
    stroke-width: 1.5854;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st1 {
    fill: #f0f0f8;
    stroke: #6a79ba;
    stroke-width: 1.585;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
    
  }
  .st2 {
    display: none;
    fill: $purple;
    stroke: #ffffff;
    stroke-width: 3.713;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st3 {
    display: none;
    fill: #855fa8;
    stroke: #ffffff;
    stroke-width: 3.713;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st4 {
    fill: #eff0f8;
    stroke: #6a78ba;
    stroke-width: 1.5854;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st5 {
    fill: #ffffff;
    stroke: #6a78ba;
    stroke-width: 1.5854;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st6 {
    fill: #d1d4eb;
    stroke: #6a78ba;
    stroke-width: 1.585;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st7 {
    display: none;
    fill: #855ea7;
    stroke: #ffffff;
    stroke-width: 3.713;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st8 {
    fill: #d1d4eb;
    stroke: #6a79ba;
    stroke-width: 1.585;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
    
  }
  .st9 {
    display: none;
    fill: #845ba6;
    stroke: #ffffff;
    stroke-width: 3.713;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st10 {
    display: none;
    fill: #855ca6;
    stroke: #ffffff;
    stroke-width: 3.713;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st11 {
    display: none;
    fill: #eff0f8;
    stroke: #6a78ba;
    stroke-width: 1.5854;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st12 {
    display: none;
  }
  .st13 {
    display: inline;
    fill: #eff0f8;
    stroke: #6a78ba;
    stroke-width: 1.5854;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st14 {
    display: inline;
    fill: #cfcce6;
    stroke: #ffffff;
    stroke-width: 3.7132;
    stroke-miterlimit: 10;
  }
  .st15 {
    display: none;
    fill: #835aa6;
    stroke: #ffffff;
    stroke-width: 3.7132;
    stroke-miterlimit: 10;
  }
  .st16 {
    fill: #f0f0f8;
    stroke: #6a79ba;
    stroke-width: 1.585;
    stroke-miterlimit: 10;
  }
  .st17 {
    display: none;
    fill: #855ca6;
    stroke: #ffffff;
    stroke-width: 3.713;
    stroke-miterlimit: 10;
  }
  .st18 {
    fill: #f0f0f8;
    stroke: #6a78ba;
    stroke-width: 1.5854;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st19 {
    display: none;
    fill: #845ba6;
    stroke: #ffffff;
    stroke-width: 3.713;
    stroke-miterlimit: 10;
  }
  .st20 {
    fill: #545554;
  }
  .st21 {
    fill: #f0f0f8;
    stroke: #6a79bb;
    stroke-width: 1.585;
    stroke-miterlimit: 10;
  }
  .st22 {
    display: inline;
  }
  .st23 {
    display: inline;
    fill: #d1d4eb;
    stroke: #6a78ba;
    stroke-width: 1.5854;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st24 {
    fill: none;
    stroke: #6a78ba;
    stroke-width: 1.5854;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st25 {
    fill: #f0a798;
    stroke: #6a78ba;
    stroke-width: 3.7132;
    stroke-miterlimit: 10;
  }
  path {
    transition: all .2s linear;
    cursor: pointer;
  }
  .activeMuscle{
    fill: #a347f1;
  }
}
