.foodLoader {
  width: 22px;
  height: 22px;
  border: 1px solid;
  border-color: #666666 transparent;
  border-radius: 100%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
