#womanFront .st0 {
  display: none;
  fill: #d1d4eb;
  stroke: #6a78ba;
  stroke-width: 2.1138;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#womanFront .st1 {
  fill: #d1d4eb;
  stroke: #6a78ba;
  stroke-width: 1.585;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#womanFront .st2 {
  fill: #f0f0f8;
  stroke: #6a78ba;
  stroke-width: 1.585;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#womanFront .st3 {
  fill: #ffffff;
  stroke: #6a78ba;
  stroke-width: 1.585;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#womanFront .st4 {
  fill: #d1d4eb;
  stroke: #6a78ba;
  stroke-width: 2.1138;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#womanFront .st5 {
  fill: #f0f0f8;
  stroke: #6a78ba;
  stroke-width: 2.1138;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#womanFront .st6 {
  fill: #f0f0f8;
  stroke: #6a79ba;
  stroke-width: 1.585;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#womanFront .st7 {
  fill: none;
  stroke: #6a78ba;
  stroke-width: 2.1138;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#womanFront .st8 {
  fill: #f0f0f8;
  stroke: #6a79ba;
  stroke-width: 2.114;
  stroke-miterlimit: 10;
}
#womanFront .st9 {
  fill: #d1d4eb;
  stroke: #6a78ba;
  stroke-width: 1.5854;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#womanFront .st10 {
  fill: #eff0f8;
  stroke: #6a78ba;
  stroke-width: 1.5854;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#womanFront .st11 {
  fill: #ffffff;
  stroke: #6a78ba;
  stroke-width: 1.5854;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#womanFront .st12 {
  fill: #d1d4eb;
  stroke: #6a79ba;
  stroke-width: 1.585;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#womanFront .st13 {
  fill: #845ba6;
  stroke: #ffffff;
  stroke-width: 3.713;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#womanFront .st14 {
  fill: #f0f0f8;
  stroke: #6a79ba;
  stroke-width: 1.585;
  stroke-miterlimit: 10;
}
#womanFront .st15 {
  fill: #f0f0f8;
  stroke: #6a79bb;
  stroke-width: 1.585;
  stroke-miterlimit: 10;
}
#womanFront .st16 {
  fill: #f0f0f8;
  stroke: #6a78ba;
  stroke-width: 1.5854;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#womanFront .st17 {
  fill: #545554;
}
#womanFront .st18 {
  fill: #212121;
}
#womanFront .st19 {
  opacity: 0.3;
}
#womanFront .st20 {
  fill: #1c1a1b;
}
#womanFront .st21 {
  opacity: 0.3;
  fill: #212121;
}
#womanFront .st22 {
  fill: #010101;
}
#womanFront .st23 {
  fill: #f0f0f8;
  stroke: #6a79bb;
  stroke-miterlimit: 10;
}
#womanFront .st24 {
  fill: #ffffff;
}
#womanFront .st25 {
  fill: #312e2f;
}
#womanFront .st26 {
  display: none;
  fill: #4b4b4b;
}
#womanFront .st27 {
  display: none;
  fill: #6b6c6c;
}
#womanFront .st28 {
  fill: #4b4b4b;
}
#womanFront .st29 {
  opacity: 0.5;
  fill: #212121;
}
#womanFront .st30 {
  opacity: 0.5;
}
#womanFront path {
  transition: all 0.2s linear;
  cursor: pointer;
}
#womanFront .activeMuscle {
  fill: #a347f1;
}/*# sourceMappingURL=style.css.map */