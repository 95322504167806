body {
  background-color: #f5f7f8;
}
.hiText {
  background: -webkit-linear-gradient(90deg, #f02484, #5408a9 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.glass {
  background: rgba(255, 255, 255, 0.22);

  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(3.9px);
  -webkit-backdrop-filter: blur(10.9px);
  border: 1px solid rgba(255, 255, 255, 0.23);
}
/* .css-13cymwt-control{
  height: 32px !important;
  border-radius: 20px !important;
  border-color: #DDCEEE !important; 
} */
.css-1u9des2-indicatorSeparator {
  display: none !important;
}
.css-1dimb5e-singleValue {
  font-size: 12px !important;
  color: #986bcb !important;
}
.css-1xc3v61-indicatorContainer {
  color: #986bcb !important;
}
