#womanBack .st0 {
  fill: #D1D4EB;
  stroke: #6A79BB;
  stroke-width: 1.585;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#womanBack .st1 {
  fill: #EFF0F8;
  stroke: #6A79BB;
  stroke-width: 1.585;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#womanBack .st2 {
  fill: #F0F0F8;
  stroke: #6A79BB;
  stroke-width: 1.585;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#womanBack .st3 {
  fill: #FFFFFF;
  stroke: #6A79BB;
  stroke-width: 1.585;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#womanBack .st4 {
  display: none;
  fill: #EFF0F8;
  stroke: #6A78BA;
  stroke-width: 1.585;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#womanBack .st5 {
  opacity: 0.39;
  fill: #F0F0F8;
  stroke: #6A79BB;
  stroke-width: 1.585;
  stroke-miterlimit: 10;
}
#womanBack .st6 {
  fill: #D1D4EB;
  stroke: #6A79BA;
  stroke-width: 1.5854;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#womanBack .st7 {
  fill: #F0F0F8;
  stroke: #6A79BA;
  stroke-width: 1.5854;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#womanBack .st8 {
  fill: #D1D4EB;
  stroke: #6A78BA;
  stroke-width: 1.5854;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#womanBack .st9 {
  fill: #212121;
}
#womanBack .st10 {
  fill: #F0F0F8;
  stroke: #6A79BA;
  stroke-width: 1.585;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#womanBack .st11 {
  fill: #D1D4EB;
  stroke: #6A79BA;
  stroke-width: 1.585;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#womanBack .st12 {
  fill: #EFF0F8;
  stroke: #6A78BA;
  stroke-width: 1.5854;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#womanBack .st13 {
  opacity: 0.61;
  fill: #FFFFFF;
  stroke: #565692;
  stroke-width: 1.585;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#womanBack .st14 {
  fill: #D1D4EB;
  stroke: #6A79BB;
  stroke-width: 1.5854;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#womanBack .st15 {
  fill: #F0F0F8;
  stroke: #6A79BA;
  stroke-width: 1.5854;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#womanBack .st16 {
  fill: #F0F0F8;
  stroke: #6A79BB;
  stroke-width: 1.5854;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#womanBack .st17 {
  fill: #F0F0F8;
  stroke: #6A79BB;
  stroke-width: 1.5854;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#womanBack .st18 {
  fill: #D1D4EB;
  stroke: #6A79BB;
  stroke-width: 1.5854;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#womanBack .st19 {
  fill: #F0F0F8;
  stroke: #6A79BA;
  stroke-width: 1.585;
  stroke-miterlimit: 10;
}
#womanBack .st20 {
  fill: #F0F0F8;
  stroke: #6A79BB;
  stroke-width: 1.585;
  stroke-miterlimit: 10;
}
#womanBack .st21 {
  fill: #855EA7;
  stroke: #FFFFFF;
  stroke-width: 3.713;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#womanBack .st22 {
  fill: #EFF0F8;
  stroke: #6A78BA;
  stroke-width: 1.585;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
#womanBack .st23 {
  fill: #1D1B1A;
}
#womanBack .st24 {
  fill: #232323;
}
#womanBack .st25 {
  opacity: 0.51;
}
#womanBack .st26 {
  fill: #FFFFFF;
}
#womanBack path {
  transition: all 0.2s linear;
  cursor: pointer;
}
#womanBack .activeMuscle {
  fill: #a347f1;
}/*# sourceMappingURL=style.css.map */