
#womanBack{
  .st0{fill:#D1D4EB;stroke:#6A79BB;stroke-width:1.585;stroke-linejoin:round;stroke-miterlimit:10;}
	.st1{fill:#EFF0F8;stroke:#6A79BB;stroke-width:1.585;stroke-linejoin:round;stroke-miterlimit:10;}
	.st2{fill:#F0F0F8;stroke:#6A79BB;stroke-width:1.585;stroke-linejoin:round;stroke-miterlimit:10;}
	.st3{fill:#FFFFFF;stroke:#6A79BB;stroke-width:1.585;stroke-linejoin:round;stroke-miterlimit:10;}
	.st4{display:none;fill:#EFF0F8;stroke:#6A78BA;stroke-width:1.585;stroke-linejoin:round;stroke-miterlimit:10;}
	.st5{opacity:0.39;fill:#F0F0F8;stroke:#6A79BB;stroke-width:1.585;stroke-miterlimit:10;}
	.st6{fill:#D1D4EB;stroke:#6A79BA;stroke-width:1.5854;stroke-linejoin:round;stroke-miterlimit:10;}
	.st7{fill:#F0F0F8;stroke:#6A79BA;stroke-width:1.5854;stroke-linejoin:round;stroke-miterlimit:10;}
	.st8{fill:#D1D4EB;stroke:#6A78BA;stroke-width:1.5854;stroke-linejoin:round;stroke-miterlimit:10;}
	.st9{fill:#212121;}
	.st10{fill:#F0F0F8;stroke:#6A79BA;stroke-width:1.585;stroke-linejoin:round;stroke-miterlimit:10;}
	.st11{fill:#D1D4EB;stroke:#6A79BA;stroke-width:1.585;stroke-linejoin:round;stroke-miterlimit:10;}
	.st12{fill:#EFF0F8;stroke:#6A78BA;stroke-width:1.5854;stroke-linejoin:round;stroke-miterlimit:10;}
	.st13{opacity:0.61;fill:#FFFFFF;stroke:#565692;stroke-width:1.585;stroke-linejoin:round;stroke-miterlimit:10;}
	.st14{fill:#D1D4EB;stroke:#6A79BB;stroke-width:1.5854;stroke-linejoin:round;stroke-miterlimit:10;}
	.st15{fill:#F0F0F8;stroke:#6A79BA;stroke-width:1.5854;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
	.st16{fill:#F0F0F8;stroke:#6A79BB;stroke-width:1.5854;stroke-linejoin:round;stroke-miterlimit:10;}
	.st17{fill:#F0F0F8;stroke:#6A79BB;stroke-width:1.5854;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
	.st18{fill:#D1D4EB;stroke:#6A79BB;stroke-width:1.5854;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
	.st19{fill:#F0F0F8;stroke:#6A79BA;stroke-width:1.585;stroke-miterlimit:10;}
	.st20{fill:#F0F0F8;stroke:#6A79BB;stroke-width:1.585;stroke-miterlimit:10;}
	.st21{fill:#855EA7;stroke:#FFFFFF;stroke-width:3.713;stroke-linejoin:round;stroke-miterlimit:10;}
	.st22{fill:#EFF0F8;stroke:#6A78BA;stroke-width:1.585;stroke-linejoin:round;stroke-miterlimit:10;}
	.st23{fill:#1D1B1A;}
	.st24{fill:#232323;}
	.st25{opacity:0.51;}
	.st26{fill:#FFFFFF;}
	path {
		transition: all 0.2s linear;
		cursor: pointer;
	}
	.activeMuscle {
		fill: #a347f1;
	}
}