
#womanFront{
.st0 {
  display: none;
  fill: #d1d4eb;
  stroke: #6a78ba;
  stroke-width: 2.1138;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.st1 {
  fill: #d1d4eb;
  stroke: #6a78ba;
  stroke-width: 1.585;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.st2 {
  fill: #f0f0f8;
  stroke: #6a78ba;
  stroke-width: 1.585;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.st3 {
  fill: #ffffff;
  stroke: #6a78ba;
  stroke-width: 1.585;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.st4 {
  fill: #d1d4eb;
  stroke: #6a78ba;
  stroke-width: 2.1138;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.st5 {
  fill: #f0f0f8;
  stroke: #6a78ba;
  stroke-width: 2.1138;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.st6 {
  fill: #f0f0f8;
  stroke: #6a79ba;
  stroke-width: 1.585;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.st7 {
  fill: none;
  stroke: #6a78ba;
  stroke-width: 2.1138;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.st8 {
  fill: #f0f0f8;
  stroke: #6a79ba;
  stroke-width: 2.114;
  stroke-miterlimit: 10;
}
.st9 {
  fill: #d1d4eb;
  stroke: #6a78ba;
  stroke-width: 1.5854;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.st10 {
  fill: #eff0f8;
  stroke: #6a78ba;
  stroke-width: 1.5854;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.st11 {
  fill: #ffffff;
  stroke: #6a78ba;
  stroke-width: 1.5854;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.st12 {
  fill: #d1d4eb;
  stroke: #6a79ba;
  stroke-width: 1.585;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.st13 {
  fill: #845ba6;
  stroke: #ffffff;
  stroke-width: 3.713;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.st14 {
  fill: #f0f0f8;
  stroke: #6a79ba;
  stroke-width: 1.585;
  stroke-miterlimit: 10;
}
.st15 {
  fill: #f0f0f8;
  stroke: #6a79bb;
  stroke-width: 1.585;
  stroke-miterlimit: 10;
}
.st16 {
  fill: #f0f0f8;
  stroke: #6a78ba;
  stroke-width: 1.5854;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.st17 {
  fill: #545554;
}
.st18 {
  fill: #212121;
}
.st19 {
  opacity: 0.3;
}
.st20 {
  fill: #1c1a1b;
}
.st21 {
  opacity: 0.3;
  fill: #212121;
}
.st22 {
  fill: #010101;
}
.st23 {
  fill: #f0f0f8;
  stroke: #6a79bb;
  stroke-miterlimit: 10;
}
.st24 {
  fill: #ffffff;
}
.st25 {
  fill: #312e2f;
}
.st26 {
  display: none;
  fill: #4b4b4b;
}
.st27 {
  display: none;
  fill: #6b6c6c;
}
.st28 {
  fill: #4b4b4b;
}
.st29 {
  opacity: 0.5;
  fill: #212121;
}
.st30 {
  opacity: 0.5;
}
path {
  transition: all 0.2s linear;
  cursor: pointer;
}
.activeMuscle {
  fill: #a347f1;
}
}