.input {
  @apply w-full max-w-[100%] h-[40px] mx-0 my-[5px] p-[15px] text-xs  border-none;
  outline: none;
  transition: 0.25s ease;
  border-radius: 8px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1411764706);
}
.input.round {
  border: 1px solid #DDCEEE !important;
  border-radius: 20px;
  box-shadow: none;
}/*# sourceMappingURL=style.css.map */