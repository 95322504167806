.darkSelect {
  /*.ant-select-dropdown .ant-select-item{
    color: white;
  } */
}
.darkSelect.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  @apply bg-[#adaaaa3a]  border-none;
}
.darkSelect.ant-select .ant-select-arrow {
  @apply text-white;
}
.darkSelect .ant-select-open .ant-select-selector {
  @apply text-white;
}
.darkSelect .ant-select-selector {
  @apply text-white;
}
.darkSelect .ant-select-selection-placeholder {
  @apply text-white;
}

.ant-select-item-option,
.ant-select-selection-item {
  font-family: "yekanBakh";
}/*# sourceMappingURL=style.css.map */